.body {
  height: auto !important;
  background-position: center !important;
  padding: 0px 0px !important;
  margin-bottom: 20px !important;
  color: #636363 !important;
}

.reclaimer_sign {
    background: url(/assets/Signature/img/bg.png) !important;
    
    background-repeat: no-repeat !important;
    background-size: cover !important;

    /*
*******************************************
Template Name: Reclaimer signature
Author: abhilash gp
Date: 20-04-2022
Template By: Vandalay Designs
Copyright 2022-2023 Vandalay Business Solution

* This file contains the styling for reclaimer, this
is the file you need to edit to change the look of the site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - FOOTER 

============================================
============================================

*/

    /*================================================
01 - Sass Variables
==================================================*/

    /*================================================
01 - Sass Variables
==================================================*/

    $background_color_1: #ffffff;
    $background_color_2: #f5ad34;
    $background_color_3: #545454;
    $background_color_4: #f7a900;
    $background_color_5: #545454;
    $background_color_9: #f3f3f3;
    $border_color_1: #5b97ce;
    $border_1: #69a2d7;
    $border_2: #5f5f5f;

    $color_1: #ffffff;
    $color_2: #636363;
    $color_3: #5b97ce;
    $color_4: #beb8b8;
    $color_6: #fce4c7;
    $color_7: #000;

    html {
        scroll-behavior: smooth;
    }

    a {
        cursor: pointer;
        color: #036dff !important;
    }

    /*================================================
03 - Body
==================================================*/
   
    .loader-logo {
        height: 40px;
        margin-top: 50px;
    }

    .review_icon {
        width: 100px;
        margin-top: 130px;
    }
    @media screen and (min-width: 1px) {
        input.radio {
            position: absolute;
            left: -9999px;
        }
    }

    .sign-body {
        background: $background_color_2;
    }

    .signature-part {
        background: $background_color_1;
        border: 1px solid #cecece;
        border-radius: 0px;
        padding: 15px 0px 0px;
        margin: 0px 0 !important;
        box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.63);
        .sign-logo {
            height: 50px;
        }

        h1 {
            font-size: 18px;
            color: #222222;
            font-weight: 700;
            margin: 20px 0;
            padding: 0px;
        }
        .modal-content {
            border: 0 !important;
            border-radius: 0 !important;
        }
        .modal-header {
            margin: 0px;
            padding: 0px;
            .h2_style {
                text-align: center;
                font-size: 22px;
                color: rgb(255, 255, 255);
                padding: 20px 15px;
                font-weight: 600;
                background: #0a0d49;
                margin-bottom: 0px;
            }
            .pop3{
                font-size: 14px;
                color: #333;
                text-align: center;
                text-transform: lowercase;
                line-height: 22px;
                font-weight: 400;
            }
        }
        .ensure_li {
            margin: 0px;
            padding: 0px;
            .sign-question {
                .signature-ul {
                    text-align: center;
                    padding: 0;
                    margin: 35px 0 15px;
                    li {
                      
                        background: url(/assets/Signature/img/arrow-li.png) no-repeat 0;
                        list-style: none;
                        padding: 5px 0 5px 35px;
                        margin-bottom: 0;
                        font-size: 17px;
                        border: 0 !important;
                        text-align: left;
                        display: block;
                    }
                }
            }
            .signature_canvaseWrap {
                overflow: hidden;
                .signature-pad {
                    border: 1px dotted #000 !important;
                    background-color: #fff;
                    border-radius: 5px;
                    display: block;
                    margin: 20px auto;
                    position: relative;
                    width: 80%;
                    height: 300px;
                }

                 .sigCanvas {
                    border: 1px dotted #000 !important;
                    background-color: #fff;
                    border-radius: 5px;
                    display: block;
                    // margin: 20px auto;
                    position: relative;
                }
            }
        }
        .bord {
            padding: 5px 25px;
            .custom-control-label {
                font-size: 16px;
                line-height: 1.5em;
                padding-left: 5px;
            }
        }
        .form-check-label{
            font-size: 14px;
        }

        /*================================================
04 - Footer
==================================================*/
        .modal-footer {
            margin-top: 8px;
            .btn-clear,
            .btn-submit {
                font-size: 15px;
                color: #fff;
                font-weight: 400;
                font-size: 20px !important;
                border-radius: 4px;
                border: 0;
                width: auto;
                cursor: pointer;
                text-transform: uppercase;
            }
            .btn-clear {
                background-color: #fb0404;
                padding: 9px 15px;
            }
            .btn-submit {
                background-color: #309814;
                padding: 9px 50px;
                float: right;
            }
        }
    }

    .error_msg {
        color: #fff;
        width: 100%;
        float: left;
        background: red;
        font-size: 13px;
        padding: 0 5px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        margin-bottom: 0;
        text-align: center;
    }

    /*================================================
05 - Media Query
==================================================*/

    @media (max-width: 576px) {
        .container {
            max-width: 100%;
        }
        .signature-pad {
            width: 400px;
        }

        .signature-part {
            .sign-logo {
                height: 25px;
            }
        }
    }

    @media (max-width: 576px) {
        .signature-part .ensure_li .signature_canvaseWrap .signature-pad {
            height: 250px;
        }
        .signature-part .ensure_li .signature_canvaseWrap .signature-pad {
            width: 95%;
        }
        .signature-part {
            .modal-header {
                .h2_style {
                    padding: 14px 8px;
                    font-size: 17px;
                }
            }
            .bord {
                padding: 5px 5px;
                .custom-control-label {
                    font-size: 14px;
                }
            }
            .modal-footer {
                .btn-clear,
                .btn-submit {
                    font-size: 14px !important;
                }
            }
            .ensure_li {
                .sign-question {
                    .signature-ul {
                        li {
                            font-size: 15px;
                            padding: 5px 0 5px 32px;
                        }
                    }
                }
                .nopadmob {
                    padding: 0px 5px;
                }
            }
        }
    }

    /*========================

==  =====================*/

    $min-break-small: 576px;
    @media (max-width: $min-break-small) {
    }

    $min-break-tablet: 768px;
    @media (max-width: $min-break-tablet) {
    }
}
