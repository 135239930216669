@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');



.medicare_landingpage_02 {

  /*
    *******************************************
    Template Name: Medicare - Saver
    Author: Arun Ravi
    Date: 18-02-2022
    Template By: Vandalay Designs
    Copyright 2020-2021 Vandalay Business Solution

    * This file contains the styling for the Purplelegalcliams, this
    is the file you need to edit to change the look of the
    site.

    This files contents are outlined below >>>>

    *** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
    *******************************************
    ============================================
    ==== T A B L E   O F   C O N T E N T S =====
    ============================================
    01 - Sass Variables
    02 - BODY
    03 - FOOTER
    04 - MEDIA QUERY

    ============================================
    ============================================
  */
  /*================================================
    01 - Sass Variables
  ==================================================*/
  $background_color_1: #ffffff;
  $background_color_2: #000;
  $background_color_3: #f80725;
  $background_color_4: #333333;
  $background_color_5: #0083c2;
  $color_1: #ffffff;
  $color_2: #000;
  $color_3: #009bdf;
  $color_4: #beb8b8;
  $color_5: #636363;
  $color_6: #828282;
  $color_7: #777;
  $font_family_3: 'Open Sans', sans-serif;
  $font_size_1: 12px;
  $font_size_2: 14px;
  $font_size_3: 15px;
  $font_size_4: 16px;
  $font_size_5: 18px;
  $font_size_6: 20px;
  $font_size_7: 24px;
  $font_size_8: 13px;
  $font_size_9: 40px;
  $font_size_10: 22px;
  $font_size_11: 23px;
  $font-bold: bold;
  $font-normal: normal;
  $font-w500: 500;
  // ======Border Styles===============
  $border_color_1: #fff;
  $border_color_2: #ced4da;
  /*================================================
    02 - BODY
  ==================================================*/
  html {
    scroll-behavior: smooth;
  }
  a {
    cursor: pointer;
  }
  .txl {
    text-align: left !important;
  }
  .hide {
    display: none !important;
  }
  .show {
    display: block !important;
  }
  header {
    padding: 10px 0px;

    .logo {
      img {
        height: 90px;
      }
    }
    p {
      font-size: $font_size_6;
      text-align: right;
    }
    .ph_num {
      font-weight: $font-bold;
      font-size: $font_size_7;
      color: $color_3;
    }
    .hder_btn {
      width: 100%;
      background: $background_color_3;
      font-size: $font_size_8;
      padding: 7px;
      font-weight: $font-bold;
      color: $color_1;
      img {
        height: 20px;
        position: relative;
        top: -2px;
        left: -3px;
      }
    }
  }
  .heding_sec {
    background: url(../img/banner.jpg) 0px -98px no-repeat;
    text-align: center;
    padding: 20px 0 75px;
    background-size: 100%;
    h1 {
      color: $color_1;
      font-size: $font_size_9;
      font-weight: $font-bold;
      padding-top: 20px;
    }
  }
  .form-part {
    padding-bottom: 50px;
    form {
      .option_but {
        background: #2f9de8;
        display: inline-block;
        border: 0px solid #0070ba;
        border-bottom: 3px solid #257eba;
        font-size: 20px;
        color: $color_1;
        font-weight: 600;
        border-radius: 1px !important;
        box-sizing: border-box;
        width: 65%;
        cursor: pointer;
        margin: 0 0 20px !important;
        position: relative;
        overflow: hidden;
        text-align: center;
        padding: 16px 15px;
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .mb-0 {
        margin-bottom: 0 !important;
      }
      .option_but.active {
        border-color: #0070ba;
        background-color: #3ebbe9;
        z-index: 100;
        color: #fff;
      }
      .option_notbut {
        text-align: center;
        width: 100%;
        font-size: 18px;
        text-decoration: underline;
        font-weight: 600;
        color: $color_2;
      }
      label {
        input[type="radio"] {
          display: none !important;
        }
      }
      h3 {
        margin: 0 !important;
        padding: 0px 0 20px;
        font-size: 21px !important;
        color: $color_2;
        text-align: center;
      }
      .lst_stp {
        font-size: 26px !important;
      }
      .laststp_p {

        a {
          text-decoration: none;
          color: $color_5;
        }
      }
      .continue_btn {
        background: $background_color_5;
        width: 40%;
        color: $color_1;
        font-size: $font_size_10;
        padding: 15px;
        margin-top: 30px;
        border-bottom: 4px solid #085980;
        font-weight: $font-bold;
      }
      .form-select {
        color: $color_6;
        border: 1px solid #ced4da;
      }
      .post_cde {
        width: 60%;
        margin: 0px auto !important;
      }
      input {
        padding: 10px;
      }
      .yer {
        font-size: $font_size_11;
        font-weight: $font-bold;
        margin-right: 5px;
      }
      .yr_birth {
        width: 48%;
        font-size: $font_size_11;
        font-weight: $font-normal;
        line-height: 1.5;
        color: #212529;
        background-color: $background_color_1;
        background-clip: padding-box;
        border: none;
        appearance: none;
        border-radius: .25rem;
        padding: 0px;
        &:focus-visible {
          outline: none;
        }
      }
      .year_bx {
        border: 1px solid $border_color_2;
        width: 60%;
        border-radius: .25rem;
        padding: 5px 10px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }
      select {
        padding: 10px;
      }
      .smlwidth_inpt {
        width: 60%;
        margin-left: auto !important;
        margin-right: auto !important;
      }
      .input-group {
        width: 60%;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 10px;
      }
      .input-group-text {
        padding: 10px;
      }
      .top_minus {
        position: relative;
        margin-top: -55px;
      }
      .top_minus_1 {
        position: relative;
        margin-top: -63px;
      }
    }
    .client_call {
      h6 {
        font-size: $font_size_5;
        font-weight: $font-bold;
        img {
          height: 25px;
          margin-right: 1px;
          position: relative;
          top: -5px;
        }
        a {
          text-decoration: none;
          color: $color_7;
        }
      }
      .spek_agent {
        font-size: $font_size_5;
        margin-bottom: 10px;
      }
    }
  }
  .nopad {
    padding: 0px !important;
  }
  .error_msg {
    color: $color_1;
    width: 100%;
    float: left;
    background: #f00;
    font-size: 13px;
    padding: 0px 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 10px;
  }
  .error_msg_dob {
    color: $color_1;
    width: 100%;
    background: #f00;
    font-size: 13px;
    padding: 0px 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 10px;
  }
  .validate {
    position: relative;
    float: right;
    margin-top: -38px;
    right: 2px;
  }
  .success {
    background: url("../img/v-tick.png") no-repeat center center;
    width: 23px;
    height: 23px;
  }
  .error {
    background: url("../img/v-close.png") no-repeat center center;
    width: 23px;
    height: 23px;
  }
  @media screen and (min-width: 1px) {
    input.radio {
      position: absolute;
      left: -9999px;
    }
  }
  /*================================================
    03 - FOOTER
  ==================================================*/
  footer {
    padding: 25px 0;
    background: $background_color_4;
    .brdr-left {
      border-left: solid 1px $color_4;
    }
    .m-0 {
      margin: 0px;
    }
    ul {
      padding: 0px;
      margin-top: 15px;
      li {
        list-style: none;
        border-right: 1px solid $color_4;
        padding: 0px 10px;
        display: inline;
        color: $color_4;

        &:first-child {
          padding-left: 0px;
        }
        &:last-child {
          border: 0px;
        }

        a {
          text-decoration: none;
        }
      }
    }
    p {
      color: $color_4;
      font-size: 14px;
    }
  }
  /*================================================
    04 - MODAL
  ==================================================*/
  .modal-open .modal {
    overflow-x: hidden !important
  }
  .modal-backdrop {
    opacity: .5 !important;
  }
  .modal-header {
    border: 0px !important;
  }
  .analyseClass {
    background: #000000ad !important;
    margin-top: auto;
  }
  .modal-content {
    position: relative !important;
    top: 280px !important;
    .modal-body {
      padding: 0px 15px 15px 15px !important;
      font-size: 18px !important;
      text-align: center !important;
    }
    .modal-header {
      border-bottom: none !important;
      padding: 10px 10px 0px 10px !important;
    }
  }
  .age_modal {
    background: #000000ad;
    .modal-content {
      position: relative;
      top: 280px;
      .modal-header {
        border-bottom: none;
        padding: 10px 10px 0px 10px;
      }
      .modal-body {
        padding: 0px 15px 15px 15px;
        font-size: 18px;
        text-align: center;
      }
    }
  }
  /*================================================
    05 - MEDIA QUERY
  ==================================================*/
  @media (min-width: 1367px) {
    footer {
      width: 100% !important;
      position: fixed !important;
      bottom: 0px !important;
    }
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    .heding_sec {
      background: url(../img/banner.jpg) 0px 0px no-repeat;
      background-size: cover;
    }
    .form-part {
      form {
        padding: 0px 15px;
        .option_but {
          width: 100% !important;
        }
        .continue_btn {
          width: 100%;
        }
        .post_cde {
          width: 100%;
        }
        h3 {
          padding: 0px 15px 20px 15px;
        }
        .smlwidth_inpt {
          width: 100%;
        }
        .input-group {
          width: 100%;
        }
        .year_bx {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .mobspacer {
      height: 270px;
    }
    .heding_sec {
      background: url(../img/banner.jpg) 0px 0px no-repeat;
      background-size: cover;
    }
    .form-part {
      form {
        padding: 0px 15px;
        .option_but {
          width: 100% !important;
        }
        .continue_btn {
          width: 100%;
        }
        .post_cde {
          width: 100%;
        }
        h3 {
          padding: 0px 15px 20px 15px;
        }
        .smlwidth_inpt {
          width: 100%;
        }
        .input-group {
          width: 100%;
        }
        .year_bx {
          width: 100%;
        }
      }
    }
    footer {
      height: auto;
      padding: 25px 0;
      background: #424242;
      text-align: left;
      p {
        margin: 7px 0 20px;
        padding: 0;
        font-size: 13px;
        color: #fff;
        font-weight: 300;
        text-align: center;
        a {
          color: #b1b1b1;
        }
      }
    }
    .brdr-left {
      border: 0px !important;
    }
  }
  @media (min-width: 576px) {
    .container {
      max-width: 100%;
    }
  }
  @media (max-width: 576px) {
    header {
      .logo {
        padding-right: 0px;
        img {
          height: 33px;
        }
      }
    }
    .heding_sec {
      background: url(../img/banner.jpg) -200px 0px no-repeat;
      background-size: cover;
      h1 {
        font-family: $font_family_3;
        font-size: 24px;
      }
    }
    .pads {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
    .form-part {
      form {
        padding: 0px 15px;
        .option_but {
          width: 100%;
        }
        .continue_btn {
          width: 100%;
        }
        .post_cde {
          width: 100%;
        }
        h3 {
          padding: 0px 15px 20px 15px;
          font-size: 22px !important;
        }
        .smlwidth_inpt {
          width: 100%;
        }
        .input-group {
          width: 100%;
        }
        .yr_birth {
          width: 50% !important;
        }
      }
    }
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  /*PROGRESS BAR ROUNDED*/
  $borderWidth:7px;
  $animationTime: .5s;
  $border-color-default: #eaeaea;
  $border-color-fill: #2f9de8;
  $size: 120px;
  $howManySteps: 100; //this needs to be even.
  .top60 {
    margin-top: 60px;
  }
  .progress {
    width: $size;
    height: $size !important;
    line-height: $size;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
    background: none !important;
    margin-bottom: 30px;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: $borderWidth solid $border-color-default;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
    }
    >span {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .perce {
      font-size: 28px !important;
      font-weight: 100;
    }
    .progress-left {
      left: 0;
    }
    .progress-bar {
      width: 100%;
      height: 100%;
      background: none;
      border-width: $borderWidth;
      border-style: solid;
      position: absolute;
      top: 0;
      border-color: $border-color-fill;
    }
    .progress-left .progress-bar {
      left: 100%;
      border-top-right-radius: ($size/2);
      ;
      border-bottom-right-radius: ($size/2);
      ;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
    }
    .progress-right {
      right: 0;
      .progress-bar {
        left: -100%;
        border-top-left-radius: ($size/2);
        ;
        border-bottom-left-radius: ($size/2);
        ;
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
      }
    }
    .progress-value {
      display: flex;
      border-radius: 50%;
      font-size: 1rem;
      text-align: center;
      line-height: 20px;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      font-weight: 300;
      z-index: 9;
      span {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  @for $i from 1 through $howManySteps {
    $stepName: ($i*(100 / $howManySteps));
    @if $i <=($howManySteps/2) {
      .progress[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
          animation: loading-#{$i} $animationTime linear forwards;
        }
        .progress-left .progress-bar {
          animation: 0;
        }
      }
    }
    @if $i>($howManySteps/2) {
      .progress[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
          animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
        }

        .progress-left .progress-bar {
          animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
        }
      }
    }
  }
  @for $i from 1 through ($howManySteps/2) {
    $degrees: (180/($howManySteps/2));
    $degrees: ($degrees*$i);

    @keyframes loading-#{$i} {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate($degrees);
        transform: rotate(#{$degrees}deg);
      }
    }
  }
}
.no-scroll {
  overflow: hidden !important;
}