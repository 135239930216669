@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

 /*
*******************************************
Template Name: Boomerang
Author: Shinto
Date: 16-05-2022
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling forboomerangclaims.com, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - FOOTER 


============================================
============================================

*/


/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========

$background_color_1: #ffffff;
$background_color_2: #f1f1f2;
$background_color_3: #acbd31;
$background_color_4: #636363;
$background_color_5: #359f14;
$background_color_6: #feef06;
$background_color_7: #cecece;
$background_color_8: #fb0404;
$background_color_9: #166176;
$background_color_10: #ffb700;
// ======font styles===============
$color_1: #166176;
$color_2: #000000;
$color_3: #ffffff;
$color_4: #0c6d85;
$color_5: #e77204;
$color_6: #fce4c7;
$color_7: #000;
$color_8: #636363;

$font_size_3: 13px;
$font_family_2:'Lato', sans-serif;
$font_family_1: "Roboto", sans-serif !important;

$font_size_1: 30px;
$font_size_2: 22px;
$font_size_3: 13px;
$font_size_4: 14px;
$font_size_5: 15px;
$font_size_6: 16px;
$font_size_7: 17px;
$font_size_8: 18px;
$font_size_9: 20px;

$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
// ======zero values===============
$border_0: 0px;
$margin_0: 0px;
$padding_0: 0px;

// =======floats============
$float_r: right;
$float_l: left;
// ======border===========
$border-rd4: 4px;
$border-style1: solid 1px rgba(255, 255, 255, 0.3);
$border-style2: 4px solid #195071;
$border-style3: 2px solid #d5d5d5;
$border-style4: 1px solid #b4b4b4;
$border-style5: 1px solid #2a3385;

html {
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
  color: $color_5 !important;
  text-decoration: none !important;
}

/*================================================
02 - Sass Mixins
==================================================*/
@mixin f_style1 {
  font-size: $font_size_1;
  color: $color_1;
  font-weight: $font-w500;
}
@mixin f_style2 {
  font-size: $font_size_5;
  color: $color_1;
  font-weight: $font-normal;
}
@mixin f_style3 {
  font-size: $font_size_4;
  color: $color_1;
  font-weight: $font-normal;
}
@mixin shadow-1 {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
@mixin shadow-2 {
  -webkit-box-shadow: 3px 7px 10px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 3px 7px 10px rgba(0, 0, 0, 0.16);
  box-shadow: 3px 7px 10px rgba(0, 0, 0, 0.16);
}

@mixin shadow-3 {
  -webkit-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
}

@mixin shadow-4 {
  -webkit-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
  box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
}
@mixin radius_5 {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
@mixin radius_10 {
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

@mixin radius_top {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}




body {
font-family: $font_family_2 !important; 
font-size: 14px; 
line-height: 1.5; 
color: $color_8 !important;
background: #f9f9fa;
}

header {
  .header_caption {
    width: 100%;
    text-align: center;
    background-color: #166176;
    padding: 10px;
    z-index: 999;
    h6 {
      margin: 0;
      padding: 0;
      color: #fff;
      font-size: 14px;
    }
  }
}

.top-sec {
    padding: 50px 0px 10px;
    background-color: $background_color_1;
    border-bottom: 1px solid #166176;
  img {
    height: 90px;
  }
  .img2{
    height: 70px;
  }
}

img {
  max-width: 100%;
}

.bnr_part {
  font-family: $font_family_2 !important; 
  background: $background_color_1;
  background-size: cover;
  padding: 0px 0 0px;
  /*height: 270px;*/
  height: auto;
  min-height: 690px;

  /*================================================
05 - FORM 
==================================================*/
  .form {
    font-family: $font_family_2 !important; 
    padding: 25px 34px 40px;
    h1 {
      font-weight: normal;
      color: $color_1;
      font-size: 38px;
      margin-top: 0px;
      margin-bottom: 8px;
    }
    h2 {
      font-size: 28px;
      font-weight: 400;
      color: #b7b7b7;
    }
    p {
      color: $color_2;
      font-size: 16px;
    }
    .form-section {
      background: $background_color_2;
      padding: 20px;
      width: 100%;
      h1 {
        color: $color_1;
      }
      h3 {
        margin: 0px 0 15px;
        font-size: 24px;
        color: $color_1;
      }
      -ms-line-break: normal;
      p {
        color: $color_2;
        font-size: 16px;
      }
      ul {
        padding: 0;
        list-style: none;
        li {
          color: $color_3; 
          font-size: 16px;
          background: $color_5;
          margin: 4px 0;
          padding: 0 10px 0 28px;
          width: max-content;
          background-image: url("../img/li_checked.png");
          background-position: left;
          background-repeat: no-repeat;
          border-radius: 2px;
        }
      }
      .mb-0 {
        margin-bottom: 0px;
      }
      .trustpfst {
        margin-bottom: 0;
        a {
          color: $color_2;
          text-decoration: none;
          font-size: 14px;
        }
        img {
          height: 23px;
          margin-left: 5px;
        }
      }
      .sidespace {
        padding: 0 7px;
        font-size: 17px;
      }
      .trustpi {
        height: 40px;
        margin-bottom: 30px;
      }

      .trustpilot {
        height: 35px;
      }

      .form-control {
        border-radius: 0px;
        height: 40px !important;
        padding: 5px;
        border: 1px solid #8a8a8a !important;
        width: 100%;
        font-size: 16px;
        font-weight: 300;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        &:focus {
          box-shadow: none;
          border: 1px solid #000000 !important;
        }
      }
      .form-group {
        margin-bottom: 15px;
        .privacyimg {
          position: absolute;
          right: 11px;
          top: 12px;
        }
      }

      /*radiobox*/
      
      .regulatory {
		    margin: 40px 0 0;
        p {
          font-size: 16px;
          span {
            font-size: 12px;
            font-weight: 600;
          }
        }
        img{
          height: 50px;
        }
      }


      .button-submit {
        background-color: $background_color_3;
        border-radius: 0px;
        padding: 10px 15px;
        transition: all 0.3s;
        border: 1px solid #f16022;
        width: 100%;
        max-width: 160px;
        cursor: pointer;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $color_2 !important;
        font-size: 14px;
        font-weight: bold;
        position: relative;
        text-decoration: none;
      }
      /*radiobox end*/
    }
    fieldset.scheduler-border {
      border: 1px solid #8d8d8d !important;
      padding: 8px 15px !important;
      margin: 0 0 !important;
      border-radius: 5px;
      legend {
        font-size: 17px !important;
        font-weight: 600;
        text-align: left;
        width: auto !important;
        padding: 0 10px 5px;
        margin: 0;
        float: none;
      }
      .form-check {
        width: 50%;
        float: left;
        margin-bottom: 10px;
		    padding: 0 5px;
        .custom-checkbox {
          display: none;
          &+label {
            background: #ececec url("../img/unchecked.png") no-repeat 7px center;
            border:$border-style4;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            height: 30px;
            position: relative;
            cursor: pointer;
            outline: none;
            margin: 0;
            padding: 0 0 0 28px;
            line-height: 28px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            width: 100%;
            clear: none;
            color: $color_2;
            font-weight: $font-normal;
            display: block;
            font-size: $font_size_3;
            @include shadow-4;
          
          }
          &:checked {
            &+label {
              background: $background_color_9 url("../img/checked.png") no-repeat 7px center;
              color: $color_3;
              border:$border-style1 ;
            }
          }
        }
      }

    }
    
  }

  .m_tb {
    margin: 15px 0;
    font-size: 16px;
  }

  @media screen and (min-width: 1px) {
    input.radio {
      position: absolute;
      left: -9999px;
    }
  }

  .error_msg {
    background: $background_color_8;
    color: $color_3;
    font-size: $font_size_3;
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 15px;
    padding: 5px;
    // display: none;
  }
  .validate {
    position: relative;
    float: right;
    margin-top: -35px;
    right: 3px;
    display: none;
  }
  .validate_success {
    background: url("../img/v-tick.png") no-repeat center center;
    width: 23px;
    height: 23px;
  }
  .validate_error {
    background: url("../img/v-close.png") no-repeat center center;
    width: 23px;
    height: 23px;
  }
  .validate1 {
    margin-top: -35px !important;
    right: 185px !important;
  }

}

/*================================================
07 - TESTIMONIALS 
==================================================*/



  h2 {
    font-size: 30px;
    color: #212529 !important;
    font-weight: bold;
    text-align: center;
    padding: 10px 0 40px;
    width: 100%;
  }
  h3 {
    text-align: left;
    font-size: 30px;
    padding: 0px 0;
    color: #212529 !important;
    padding: 0px;
  }
  h5 {
    font-size: 13px;
  }

  .star_img {
    height: 35px;
    margin-right: 10px;
  }



/*================================================
07 - FAQ
==================================================*/

	.accordion-section{
    font-family: $font_family_2 !important; 
		padding: 30px 0;
		background: #dfdfdf;
		h3{
			color: $color_1 !important;
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 20px;
			text-align: center;
		}
		.accordion{
			.accordion-item{
				h2{
					padding: 0;
					.accordion-button{
						background-color: $background_color_9;
						color: $color_3;
						&:focus{
							box-shadow: none;
						}
					}
					.collapsed{
						background-color: $background_color_1;
						color: $color_1;
					}
				}
				.accordion-body{
					p{
						a{
							color:$color_5 !important;
						}
					}
				}
			}
		}
	}


/*================================================
08 - FOOTER 
==================================================*/


footer {
  font-family: $font_family_2 !important; 
  background: #21212d;
  padding: 55px 0;
  margin-top: 0px;

  p {
    font-size: 13px;
    padding-top: 0;
    margin-bottom: 16px !important;
    font-weight: 400;
    line-height: 25px;
    color: #fff;
  }
  h3 {
    @include f_style3;
    text-align: left;
    font-size: $font_size_7;
    font-weight: $font-w500;
    padding: 10px 0;
    color: #ffffff !important;
  }
  ul {
    margin: 10px 0;
    padding: 0px;
    li {
      list-style: none;
      display: inline;
      margin: 0px 13px 0 0;
      padding-right: 10px;
      border-right: 1px solid $color_3;
      &:last-child {
        border: none;
      }
      a {
        color: $color_3 !important;
        font-size: 15px;
        text-decoration: none;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

/*================================================
09 - BUTTONS 
==================================================*/

.height75 {
  height: 75px;
  padding-top: 20px;
}

.button-clear {
  background-color: transparent;
  border-radius: 10px;
  padding: 7px 0;
  transition: all 0.3s;
  border: 0;
  width: auto;
  cursor: pointer;
  margin-bottom: 20px;
  color: $color_2 !important;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  text-align: left;

  &:hover {
    text-decoration: none;
  }
}

.button-submit {
  background-color: $background_color_3;
  border-radius: 0px;
  padding: 8px 15px;
  transition: all 0.3s;
  border: 1px solid #f16022;
  width: 100%;
  max-width: 160px;
  cursor: pointer;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: $color_2 !important;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  text-decoration: none;
}

.signature-div {
  max-width: 300px;
  width: 100%;
  min-height: 100px;
  border-bottom: 2px dotted #21212d;
  background: $background_color_1;
}
.backpop {
  cursor: pointer;
  font-style: italic;
  text-align: center;
}

/*================================================
10 - MODAL 
==================================================*/






.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-effect {
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  box-shadow: 0 0 0 0 #107eb4;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@-webkit-keyframes mover {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, -20px);
  }
}

@keyframes mover {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, -20px);
  }
}

.anim_grn {
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  box-shadow: 0 0 0 0 #85ff00;
}
.anim_ylw {
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  box-shadow: 0 0 0 0 #f1ee05;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* IE */

@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Opera and prob css3 final iteration */

@keyframes blink {
  0% {
    opacity: 1;
    height: 10px;
  }
  50% {
    opacity: 1;
    height: 14px;
  }
  100% {
    opacity: 1;
    height: 18px;
  }
}

/*================================================
06 - ANALYSE PAGE STYLE
==================================================*/

.analyse{
  font-family: $font_family_2 !important; 
	padding: 0px;
	margin: 0px; 
	&::before{
		z-index: -1;
		opacity: 0.3;
		content: "";
		position: fixed;
		height: 100%;
		width: 100%;
		background: #f9f9fa !important;
/*		background: -moz-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
		background: -webkit-gradient(left top, left bottom, color-stop(0%, #67c4b1), color-stop(24%, #fff), color-stop(49%, #fff), color-stop(69%, #fff), color-stop(100%, #5cc0ad)) !important;
		background: -webkit-linear-gradient(top, #2f3996 0%, #fff 24%, #fff 49%, #fff 69%, #2f3996 100%) !important;
		background: -o-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
		background: -ms-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
		background: linear-gradient(to bottom, #2f3996 0%, #fff 24%, #fff 49%, #fff 69%, #2f3996 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#67c4b1', endColorstr='#5cc0ad', GradientType=0 );*/

	}
	.questionspart{

		&::after{
		  z-index: -1;
		  content: "";
		  position: fixed;
		  height: 135px;
		  width: 100%;
		  background-size: 15%;
		  top:2%;
		  background-image: url("../img/logo.png") !important;
		  background-position:center !important;
		  background-repeat: no-repeat!important; 
		  opacity:0.60;
	  	}

		h3{
			color: $color_1;
			font-size: 24px;
			font-weight: normal;
			margin-bottom: 20px;
			text-align: center;
			span{
				color: #008cff;
			}
		}
		  .space{
			margin-top: 20%;
			img{
				width: 100px;
				margin-top: 20px;
			}
			.htext{
				 color: $color_1;
				font-size: 18px;
				font-weight: normal;
				text-align: center;
				margin-top: 10px;
			}
		  }
		  

	}
}

/*================================================
 - LENDER PAGE STYLE
==================================================*/
.lenderpage{
  font-family: $font_family_2 !important; 
	padding: 0px 0 100px;
	margin: 0px; 
  
  .error_msg {
    background: $background_color_8;
    color: $color_3;
    font-size: $font_size_3;
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 15px;
    padding: 5px;
    // display: none;
  }
	&::before{
		z-index: -1;
		opacity: 0.2;
		content: "";
		position: fixed;
		height: 100%;
		width: 100%;
		background: #fff!important;
		background: -webkit-gradient(left top,left bottom,color-stop(0,#166176),color-stop(24%,#fff),color-stop(49%,#fff),color-stop(69%,#fff),color-stop(100%,#166176))!important;
		background: linear-gradient(
		180deg
		,#166176 0,#fff 18%,#fff 50%,#fff 60%,#166176)!important;
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#67c4b1",endColorstr="#5cc0ad",GradientType=0);
		
	}
  	.questionspart{
		&::after{
		  z-index: -1;
		  content: "";
		  position: fixed;
		  height: 135px;
		  width: 100%;
		  background-size: 15%;
		  top:2%;
		//   background-image: url(../img/logo.png) !important;
		  background-position:center !important;
		  background-repeat: no-repeat!important; 
		  opacity:0.60;
	  	}
		.space{
			
			img{
				height: 100px;
				margin-top: 20px;
			}
			h3{
				color: $color_1;
				font-size: 22px;
				font-weight: 600;
				text-align: center;
				margin-top: 10px;
			}
			p{
				font-size: 16px;
			}
		}
		.form-check {
			width: 50%;
			float: left;
			margin-bottom: 10px;
			padding-left: 0;
			.custom-checkbox {
			  display: none;
			  &+label {
				background: #ececec url("../img/unchecked.png") no-repeat 7px center;
				border:$border-style4;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				height: 30px;
				position: relative;
				cursor: pointer;
				outline: none;
				margin: 0;
				padding: 0 0 0 28px;
				line-height: 28px;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				width: 98%;
				clear: none;
				color: $color_2;
				font-weight: $font-normal;
				display: block;
				font-size: $font_size_3;
				@include shadow-4;
			  
			  }
			  &:checked {
				&+label {
				  background: $background_color_9 url("../img/checked.png") no-repeat 7px center;
				  color: $color_3;
				  border:$border-style1 ;
				}
			  }
			}
		  }



      .form-check1 {
        width: 100%;
        float: left;
        margin-bottom: 10px;
		    padding: 0 0px;
        .custom-checkbox {
          display: none;
          &+label {
            background: #ececec url("../img/unchecked.png") no-repeat 7px center;
            border:$border-style4;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            height: 30px;
            position: relative;
            cursor: pointer;
            outline: none;
            margin: 0;
            padding: 0 0 0 28px;
            line-height: 28px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            width: 99%;
            clear: none;
            color: $color_2;
            font-weight: $font-normal;
            display: block;
            font-size: $font_size_3;
            @include shadow-4;
          
          }
          &:checked {
            &+label {
              background: $background_color_9 url("../img/checked.png") no-repeat 7px center;
              color: $color_3;
              border:$border-style1 ;
            }
          }
        }

      }



      fieldset {
         margin: 0 0 15px;
       border: 1px solid #ff7500;
       legend {
        font-size: 17px !important;
         font-weight: 600;
         text-align: left!important;
         width: auto!important;
        padding: 0 10px;
        margin: 0;
       }
       }
        fieldset.scheduler-border {
       border: 1px solid #ff7500 !important;
       padding: 0 10px 10px !important;
        margin: 0 0 1.5em 0 !important;
       border-radius: 5px;
       legend{
        font-size: 17px !important;
        font-weight: 600;
        text-align: left;
        width: auto!important;
        padding: 0 10px 5px;
        margin: 0;
         float: none;
        }
       } 
  	}
}
.sticky-button{
  font-family: $font_family_2 !important; 
	p{
		background:#359f14;
		padding: 15px;
		cursor: pointer;
		position: fixed;
		bottom: 0;
		width: 100%;
		margin: 0;
	a{
		color: $color_3 !important;
		font-size: 20px;
		font-weight: 600;
		text-decoration: none;
	}
	}
}
.btn-sticky-button{
  font-family: $font_family_2 !important; 
	
		background:#359f14;
		padding: 15px;
		cursor: pointer;
		position: fixed;
		bottom: 0;
		width: 100%;
		margin: 0;
		color: $color_3 !important;
		font-size: 20px;
		font-weight: 600;
		text-decoration: none;
}


.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.lender_popup{
  background: transparent !important;
  .popbord {
      border: solid 5px #004c70 !important
  }


  .btn-pops {
      background: #f7a900;
      color: #fff !important;
      font-size: 16px;
      font-weight: 600;
      width: 200px;
      padding: 15px 0;
      display: inline-block;
      border-radius: 4px;
      text-decoration: none !important;
      cursor: pointer;
      margin: 5px;
      border-radius: 10px;
  }

  .btn-pops:hover {
      background: #71b8b5;
  }
}
/*================================================
06 - MEDIA QUERY
==================================================*/

.privacy_header{
  font-family: $font_family_2 !important; 
  	padding: 10px 0;
  	border-bottom: 1px solid $color_1;
}
.Privacy{
	padding: 25px 0;
	h1{
		font-size: 30px;
		color: $color_1;
	}
	p{
		font-size: 15px;
		b{
			color: $color_5;
		}
		a{
			color: $color_5 !important;
		}
	}
	ul{
		li{
			b{
				color: $color_5;
			}
		}
	}
	h4{
		font-size: 21px;
		font-weight: 600;
		color: $color_1;
	}
  ol{
    li{
      padding:0 0 5px 8px;
      font-size: 15px;
    }
    li.tnone{
      list-style-type: none;
    }
  }
}
.privacy_end{
	padding: 15px 0;
	background-color: $background_color_9;
	h4, p{
		color: $color_3;
	}
}

// STYLE END

/*================================================
06 - MEDIA QUERY
==================================================*/

$max-break-lg: 1921px;
$max-break-desk: 1400px;
$max-break-tab: 1199.98px;
$min-break-tab: 991px;
$max-break-tablet: 990px;
$min-break-tablet: 768px;
$max-break-small: 767px;
$min-break-small: 576px;
$max-break-mob: 575px;
@media (min-width: $max-break-desk) and (max-width: $max-break-lg) {
}

@media (min-width: $min-break-tablet) and (max-width: $max-break-tab) {
}

@media screen and (max-width: $min-break-tablet) {
  .bnr_part .form_part {
    margin: 0;
  }
}

@media (min-width: $min-break-small) and (max-width: $max-break-small) {
}

@media (max-width: $max-break-small) {
	.mp-0 {
		padding: 0px !important;
	}
	.top-sec {
    padding: 15px 0 10px;
    img{
      height: auto;
      max-width: 100%;
    }
	}
	header {
		padding: 0px 0;
		.logo {
		text-align: center;
		img {
			height: 75px;
		}
		}
	}

	.bnr_part {
		.form {
		padding: 10px 0 0;
      h1 {
        font-size: 26px;
      }
      .form-section{
        .button-submit {
          max-width: 100%;
        }
      }
		}
		h2 {
		font-size: 24px;
		}
		h3 {
		font-size: 22px;
		}
		.bnr_top {
		padding: 0px;
		margin-top: 40px;
		}
    
	}

	.modal-lg {
		max-width: 100% !important;
	}

	.smallmod {
		width: 95% !important;
	}




	footer {
		text-align: left !important;
		padding: 25px 0;
		ul {
		li {
			text-align: left;
			display: inline;
			a {
			font-size: 14px !important;
			}
		}
		}
	}

	.testimonial {
		padding: 10px 0 20px;
		h2 {
		font-size: 20px;
		padding-bottom: 20px;
		}
		.t-mob {
		text-align: center;
		margin-bottom: 20px;
		h3 {
			text-align: center;
		}
		}
	}
	.lenderpage {
		.questionspart {
			.form-check{
				width: 100%;
				.custom-checkbox+label{
					width: 100%;
				}
			}
		}
	}
  .regulatory{
    .text-start{
      text-align: center !important;
    }
  }
}



$min-break-small: 576px;
@media (max-width:$min-break-small){
	.bnr_part {
		.form {
			fieldset.scheduler-border {
				padding: 8px 10px !important;
        legend{
          font-size: 15px !important;
        }
			}
		}
	}
  
 	.analyse {
		.questionspart {
			&::after{
				background-size: 40%;
				top: 20px;
				height: 115px;
			}
			.space {
				margin-top: 50%;
				h3{
					font-size: 20px;
				}
			}
		}
	}
	.signature-div {
		min-height: 150px;
	}


}



@media (max-width: 575px) {
  .bnr_part .form fieldset.scheduler-border .form-check .bigcls{
    font-size: 11px !important;
  }

  .bnr_part .form fieldset.scheduler-border .form-check .custom-checkbox + label{
    font-size: 11px !important;
  }
  .agreed-sec label{
    font-size: 12px;
  }

}


@media (max-width: 320px) {
  .bnr_part .form fieldset.scheduler-border .form-check .bigcls{
    font-size: 11px !important;
    line-height: 14px !important;
  }

  .bnr_part .form fieldset.scheduler-border .form-check .custom-checkbox + label{
    font-size: 11px !important;
  }


}



$max-break-phone:360px;
@media (max-width:$max-break-phone){
  
  .bnr_part {
    .form {
      .form-section {
        h3{
          font-size: 21px;
        }
          ul {
            li{
              font-size: 14px;
            }
          }
        }
      }
    }
    
}
.txt_black{
  color: #000;
    font-size: 15px;
    padding-left: 0px;
    cursor: pointer;
  a{
    color: #000 !important;
    text-decoration: underline !important;
  }

  }
.agreed-sec
  {
  margin-bottom: 0;  
  display:inline-block;  
  position: relative;
  width:100%;

   input[type='checkbox']{
    cursor: pointer;
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
   } 
   span.error_msg{
    margin-bottom: 0;
    margin-top: 5px;
   }
}
.agreed-sec-in{
  padding-left: 1.25rem;
}