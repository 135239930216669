
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

$font_family_1:'Poppins', sans-serif !important;
$background_color_1:#ffffff;

body {
    font-family:$font_family_1;
    background: $background_color_1 !important;
}


/*
*******************************************
Template Name: Boomerang Claims
Author: Abhilash GP
Date: 04-07-2022
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the Boomerang Claims, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - 
05 - 
06 - FOOTER
07 - MODAL
08 - Animation
09 - MEDIA QUERY

============================================
============================================

*/


/*================================================
01 - Sass Variables
==================================================*/

$background_color_2:#ff7900;
$background_color_3:#166176;
$background_color_4:#c7dbde;
$background_color_5:#22697d;
$background_color_6:#08941b;
$background_color_7:#FF0000;


$color_1:#FFFFFF;
$color_2:#222222;
$color_3: #ff7900;
$color_4: #166176;




$font_size_1:16px;
$font_size_2:14px;




// ======border===========
$border-style1:1px dashed #ffffff;




/*================================================
02 - Sass Mixins
==================================================*/

/*================================================
03 - BODY STYLES
==================================================*/
.BMC_V2{
// font-family: $font_family_1;
    

a {
    color:#ff7900 !important;
    cursor: pointer;
}



.show{
    display: block !important;
}

.hide{
    display: none !important;
}

header{
    min-height: 800px;
    overflow: hidden;
    position: relative;
    height: auto;

    .shapes-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    // .custom-btn:before{
    //     background: #08941b !important;
    // }
    .lenderselect{
        overflow: hidden !important;
    }
   
    
    .shape {
        position: relative;
    }
    
    .bg-shape {
        position: absolute;
        height: 190%;
        width: 100%;
        display: block;
        border-radius: 120px;
        background:$background_color_2;
        bottom: 0;
        right: 0;
        transform: translate(35%,-28%) rotate(-35deg);
        z-index: -1;
        @media (max-width: 991px) {
            height: 130%;
        }
    }



.notification{
    background: $background_color_3;
    color: $color_1;
    border-bottom:1px dashed #ffffff;
    z-index: 9;
  position: relative;
    p{
        margin: 0px;
        text-align: center;
        padding: 5px 0;
    }
    
}

.banner-part{
    position: relative;
    z-index: 9;
    img{
        height:80px;
        margin: 10px 0 50px;
    }
    h1{
        font-weight: 700 !important;
        font-size: 44px !important;
        color: $color_2 !important;
        line-height: 64px !important;
        margin-bottom: 50px !important;
        font-family: $font_family_1;
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: $color_2;
        line-height: 30px;
    }
    ul{
        margin: 40px 0;
        padding: 0;
        li{
            font-size: 16px;
            line-height: 26px;
            color: $color_1;
            margin-bottom: 10px;
            padding:7px 10px 7px 75px;
            background:url('../img/li-arrow.png') no-repeat left center $background_color_2;
        }
    }
}

    .form-part{
        .form-div{
            background:url('../img/mobile_iconbg.png') no-repeat top center;
            padding: 80px 15px;
            margin-top: 40px;
            min-height: 1060px;

            form{
                padding: 0px 20px 10px;
                overflow: hidden;
                h3{
                    font-weight: 600;
                    font-size: 24px;
                    color:$color_3 !important;
                    line-height: 30px;
                    text-align: center;
                    z-index: 9;
                }

                h4{
                    font-weight:600;
                    font-size: 18px;
                    color:$color_2;
                    line-height: 26px;
                    text-align: center;
                    margin-bottom: 10px;
                    z-index: 9;
                }
                p{
                    font-weight:400;
                    font-size: 13px;
                    color:$color_2;
                    line-height: 20px;
                    text-align: center;
                    margin-bottom: 10px;
                    z-index: 9; 
                }
                .form-check {
                    width: 100%;
                    float: left;
                    margin-bottom: 10px;
                    padding: 0 0px;
                    position: relative;
                    z-index: 999999;
                    .custom-checkbox {
                      display: none;
                      &+label {
                        background: $background_color_2 url("../img/unchecked.png") no-repeat 20px center;
                        border:solid 1px $color_3;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        height:52px;
                        position: relative;
                        cursor: pointer;
                        outline: none;
                        margin: 0;
                        padding: 0 0 0 60px;
                        line-height: 50px;
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        border-radius: 10px;
                        width: 100%;
                        clear: none;
                        color: $color_1;
                        font-weight: 500;
                        display: block;
                        font-size: 17px;
                       
                        &:hover {
                            z-index: 9;
                            box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                                        -4px -4px 6px 0 rgba(116, 125, 136, .5), 
                              inset -4px -4px 6px 0 rgba(255,255,255,.2),
                              inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
                          }
                      
                      }
                      &:checked {
                        &+label {
                          background: $background_color_3 url("../img/checked.png") no-repeat 20px center;
                          color: $color_1;
                          border:solid 1px $color_4;
                        }
                      }
                    }

                  }
          

                  .afca{
                        p{
                            font-weight:400;
                            font-size:15px;
                            color:$color_2; 
                            strong{
                                color: $color_3;
                            } 
                           
                        }
                        img{
                            margin: 15px 0;
                            height: 50px;
                        }
                    }
                    #css-dropdown
                    {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        width: 300px;
                        height: 42px;
                        margin: 100px auto 0 auto;
                    }

                    .form-control{
                        padding: 10px;
                        &:focus{
                            box-shadow: none;
                        }
                    }
                   
                    .form-select{
                        padding: 10px;
                        &:focus{
                            box-shadow: none;
                        }
                    }

                    .signature-div {
                        max-width: 339px;
                        // max-width: 300px;
                        width: 100%;
                        // min-height:165px;
                        min-height: 140px;
                        border: solid 1px #ddd;
                        border-bottom: 2px dotted #21212d;
                        background: #fff;
                    }



            }
        }
    }
    .input-group-text{
        // background-color: $background_color_2;
        border: solid 1px $color_3;
    }
    /*PROGRESS BAR ROUNDED*/


$borderWidth:8px;
$animationTime: .5s;
$border-color-default: #eaeaea;
$border-color-fill:#ff7900;
$size: 100px;
$howManySteps: 100; //this needs to be even. 
.top60{
  margin-top: 60px;
}
.progress{
  width: $size;
  height: $size !important;
  line-height: $size;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  background:none !important;
  margin-bottom:15px;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
  }
  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .perce{
    font-size:18px !important;
    font-weight: 100;
}

  .progress-left {
    left: 0;
  }
  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-fill;
  }
  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: ($size/2);;
    border-bottom-right-radius: ($size/2);;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress-right {
    right: 0;
    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);;
      border-bottom-left-radius: ($size/2);;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }
  .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    z-index: 9;
    div {
      //margin-top: 10px;
    }
    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
@for $i from 1 through $howManySteps {
    $stepName: ($i*(100 / $howManySteps));

    //animation only the left side if below 50%
    @if $i <= ($howManySteps/2) { 
        .progress[data-percentage="#{$stepName}"] {
            .progress-right .progress-bar {
                 animation: loading-#{$i} $animationTime linear forwards;
            }
            .progress-left .progress-bar {animation: 0;}
        }
    }
    //animation only the right side if above 50%
    @if $i > ($howManySteps/2)  {  
        .progress[data-percentage="#{$stepName}"] {
            .progress-right .progress-bar {
                animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
            }
            .progress-left .progress-bar {
      animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
    }
        }
    }
}

//animation
@for $i from 1 through ($howManySteps/2) { 
    $degrees: (180/($howManySteps/2));
    $degrees: ($degrees*$i);
    @keyframes loading-#{$i}{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate($degrees);
            transform: rotate(#{$degrees}deg);
    }
    }
}

}


/*================================================
04 - BUTTON
==================================================*/

.btn-next{
    background: $background_color_6;
    color: $color_1;
    padding: 15px 0;
    border: 0px;
    width: 100%;
    border-radius: 6px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    &:hover {
        
        box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                    -4px -4px 6px 0 rgba(116, 125, 136, .5), 
          inset -4px -4px 6px 0 rgba(255,255,255,.2),
          inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
      }
   
}

.button-clear{
    border: 0px;
    background: none;
}

.blue-but{
    background: $background_color_3;
}

/*================================================
04 - FAQ
==================================================*/

.faq{
    background-color: $background_color_4;
    padding: 0px 0 50px !important;
    h3{
        font-weight: 600;
        font-size: 44px;
        color: #166176 !important;
        line-height: 60px;
        padding: 30px 0;
        text-align: center;
    }
    .accordion-item{
        border-bottom: solid 10px $background_color_4;

        .accordion-header{
            padding: 0px;
        }
    }
    .accordion-button{
        font-weight: 500;
        font-size: 18px;
        color:$color_4;
    }
    .accordion-button:not(.collapsed){
        color: $color_1;
        background-color: $background_color_3;
    }
    .accordion-button:focus{
        box-shadow: none;
    }
}

.error_msg {
    background: $background_color_7;
    color: $color_1;
    font-size:12px;
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 15px;
    padding: 5px;
    // display: none;
  }




/*================================================
06 - FOOTER
==================================================*/
.footerwave{
    background: $background_color_4;
}
footer{
    background: $background_color_5;
    padding: 50px 0 10px;

    p {
        line-height: 24px;
        font-size: 15px;
        font-weight: 400;
        color:$color_1;
        margin: 0 !important;
        width: 100%;
        padding-bottom: 10px;
        span{
            color: $color_3;
        }
    }

    ul{
        padding: 20px 0;
        text-align: center;
        margin-bottom: 0;

        li{
            list-style: none;
            color:$color_1;
            border-right: 1px solid $color_1;
            font-size: 15px;
            padding: 0 10px;
            display: inline;
            cursor: pointer;

            &:last-child{
                border-right: none;
            }

            a {
                color: $color_1 !important;
                cursor: pointer;
            }
        } 
    }
}




    

/*================================================
07 - MODAL
==================================================*/

.modal-content{
    
    .modal-header{

        h4{
            font-size: 28px;
        }
    }

    .modal-body{
        p{
           
        }
        h3{
           
        }
    }
    

    
    table {
        border-collapse: collapse;
    }
    
    tr{
        border-bottom: 1px solid #000;
    }
    
    td{
       border-right: 1px solid #000;
       padding: 5px;
    }
    
    
}


/*================================================
08 - Animation
==================================================*/

/* Wave effect */
.wave-effect {
    position:relative;
	z-index:1;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    height:120px;
    overflow:hidden;
}

.waves-shape.shape-one {
    z-index:15;
    opacity:0.5;
}

.waves-shape.shape-two {
    z-index:10;
}

.waves-shape.shape-three {
    z-index:5;
}

.wave {
    position:absolute;
    left:0;
    width: 200%;
    height:100%;
    background-repeat:
    repeat no-repeat;
    background-position:0 bottom;
    transform-origin:
    center bottom;
}

.waves-shape {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.wave-one {
	background-image:url('../img/wave-1.png');
	background-size:50% 100px;
}

.wave-one-footer{
    background-image:url('../img/wave-1-f.png') !important;
}

.wave-two-footer {
	background-image:url('../img/wave-2-f.png') !important;
    background-size:50% 120px;
}
.wave-three-footer {
	background-image:url('../img/wave-3-f.png') !important;
    background-size:50% 100px;
}

.wave-two {
	background-image:url('../img/wave-2.png');
    background-size:50% 120px;
}

.wave-anim .wave-two {
    -webkit-animation:move-wave 10s linear infinite;
	   -moz-animation:move-wave 10s linear infinite;
			animation:move-wave 10s linear infinite;
}

.wave-three {
	background-image:url('../img/wave-3.png');
    background-size:50% 100px;
}

.wave-anim .wave-three {
    -webkit-animation:move-wave 15s linear infinite;
	   -moz-animation:move-wave 15s linear infinite;
			animation:move-wave 15s linear infinite;
}

@keyframes move-wave {
    0% {
        transform:translateX(0) translateZ(0) scaleY(1);
    }
	
    50% {
        transform:translateX(-25%) translateZ(0) scaleY(0.55);
    }
	
    100% {
        transform:translateX(-50%) translateZ(0) scaleY(1);
    }
}




/*================================================
09 - MEDIA QUERY
==================================================*/




@media (min-width:1200px) and (max-width : 1440px){
    header{
        .banner-part{
            h1{
                font-size: 38px;
                line-height: 50px;
            }
        }
        .form-part{
            .form-div{
                
                form{
                    padding: 0px 0px 10px;
                    h3{
                        font-size: 21px;  
                    }
                }
            }
        }
    }
}

$break-ipad-pro: 1024px;

$break-min-ipad: 768px;
$break-max-ipad: 990px;

$break-min-tab: 577px;
$break-max-tab: 767px;

$break-mob: 576px;

@media (min-width:$break-max-ipad)  and (max-width: $break-ipad-pro) {
    header{
        .banner-part{
            .logo{
                text-align: center;
            }
        }
        .form-part{
                .form-div{
                    background:url('../img/mobile_bg.png') no-repeat center top;
                    background-size: cover;
                }
            }
    }
}

    
@media (min-width:$break-min-ipad) and (max-width:$break-max-ipad) {
    header{
        .banner-part{
            .logo{
                text-align: center;
            }
        }
        .form-part{
                .form-div{
                    background:url('../img/mobile_bg.png') no-repeat center top;
                    background-size: cover;
                }
            }
    }
}


@media (min-width:$break-min-tab) and (max-width:$break-max-tab) {
       
}

@media screen and (max-width: $break-mob) {   
   
}

@media screen and (max-width: $break-max-tab) {   
    header{
        .bg-shape {
            height: 150%;
            position: fixed;
            border-radius: 35px;
        }
        .mobnone{
            display: none !important;
        }
        .progress{
            width: 100px;
            height: 100px !important;
            line-height: 100px;
            margin-bottom:20px;
        }
        p{
            font-size: 13px;
        }
        .banner-part{
            .logo{
                text-align: center;
            }
            img{
                min-height: auto;
                height: 45px;
                margin: 10px 0;
            }
            h1{
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 7px;
                text-align: center;
            }
            p{
                font-size: 14px;
                line-height: 20px;
                text-align: center;
            }
            ul{
                margin: 20px 0;
                li{
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        // form
        .form-part{
            padding: 0px;
            .form-div{
                background:url('../img/mobile_bg.png') no-repeat center top;
                background-size: cover;
                padding: 15px;
                margin-top: 0px;
                min-height: inherit;
                form{
                    padding: 0px;
                    .form-check {
                        .custom-checkbox{
                            &+label {
                                height: 50px;
                                line-height: 48px;
                            }
                        }
                    }
                    .afca{
                        img {
                            margin: 15px 0;
                            height: 50px;
                        }
                        p{
                            font-size: 14px;
                        }
                    }
                    h3{
                        font-size: 19px;
                    }
                    h4{
                        font-size: 17px;
                        line-height: 24px;
                    }
                }

            }
        }

        // 
    }
    // .custom-btn{
    //     margin-top: 0px !important;
    //     &:before {
    //         animation: shiny-btn1 0s ease-in-out infinite !important;
    //         display: none !important;
       
    
    //     }
    // }


// FAQ







// footer

footer{
    p{
        font-size: 13px;
    }
    ul{
        li{
            font-size: 13px; 
        }
    }
}
.wave-effect{
    height: 72px;
}

.wave-one{
    background-size: 50% 40px;
}
 .wave-two{
    background-size: 50% 50px;
 }
.wave-three{
    background-size: 50% 40px;
 }


}

// ANIMATIONS

//button  animations



.custom-btn {
    background: $background_color_6;
    color: $color_1;
    padding: 15px 0;
    border: 0px;
    width: 96%;
    border-radius: 6px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    transform: translate(10px, 10px);
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    outline: none;
    margin-top: 5px;
    overflow: hidden;
    &:hover {
        box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
          inset -4px -4px 6px 0 rgba(255,255,255,.2),
          inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
      }
      &:before {
        content:'';
            top:0;
          transform:translateX(100%);
          width:100%;
          height:220px;
          position: absolute;
          z-index:1;
          animation: slide .8s infinite;
           background:#ffffff57;
    }
  
  }
  @keyframes slide {
    0% {-webkit-transform: translateX(-100%);}
    100% {-webkit-transform:translateX(100%);}
    
}
  
  
  @-webkit-keyframes shiny-btn1 {
      0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
      80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
      81% { -webkit-transform: scale(4) rotate(45deg); opacity: 0.7; }
      100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
  }
  

//   

.anim_ylw {
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    box-shadow: 0 0 0 0 $color_3;
}

@-webkit-keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0); } }
  
  @-moz-keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0); } }
  
  @-ms-keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0); } }
  
  @keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0); } }

//    

.frame{
	width: 360px;
	height: 250px;
	margin: 125px auto 0;
	position: relative;
	background: #6a4f36;
	border-radius:3px; 
}
#button_open_envelope{
	width: 100%;
	height: auto;
    text-align: center;
	position: absolute;
	z-index: 311;
    top: -75px;
    left: 0px;
	border-radius: 10px;
	color: $color_1;
	font-size: 20px;
	padding:15px 0; 
    background: $background_color_2;
	border: 2px solid #fff;
	transition:.3s;
    &:hover{
	background: $background_color_3;
	color: $color_1;
	transform:scale(0.7);
	transition:background .25s, transform .5s,ease-in;
	cursor: pointer;
}
}

.message{
	position: relative;
	width: 94%;
	min-height:200px;
	height: auto;
	background: #fff;
	margin: 0 auto;
	top: 30px;
	box-shadow: 0 0 5px 2px #333;
	transition:2s ease-in-out;
	transition-delay:1.5s;
	z-index: 300;
}
.left,.right,.top{width: 0;	height: 0;position:absolute;top:0;z-index: 310;}
.left{	
	border-left: 360px solid #ff7900;
	border-top: 60px solid transparent;
	border-bottom: 160px solid transparent;
}
.right{	
	border-right: 360px solid #ff7900;
	border-top: 60px solid transparent;
	border-bottom: 160px solid transparent;;
	
}
.top{	
	border-right: 180px solid transparent;
	border-top: 160px solid #ff881d;
	border-left: 180px solid transparent;
	transition:transform 1s,border 1s, ease-in-out;
	transform-origin:top;
	transform:rotateX(0deg);
	z-index: 500;
}
.bottom{
	width: 360px;
	height: 210px;
	position: absolute;
	// background: #dc6800;
    background: #f67400;
	top: 60px;
	border-radius:3px;
	z-index: 310; 
}

.open{
	transform-origin:top;
	transform:rotateX(180deg);
	transition:transform .7s,border .7s,z-index .7s ease-in-out;
	border-top: 100px solid #55412e;
	z-index: 200;
}
.pull{
	-webkit-animation:message_animation 2s 1 ease-in-out;
	animation:message_animation 2s 1 ease-in-out;
	-webkit-animation-delay:.9s;
	animation-delay:.45s;
	transition:1.5s;
	transition-delay:1s;
	z-index: 350;
}
.push{
	-webkit-animation:message_animation2 2s 1 ease-in-out;
	animation:message_animation2 2s 1 ease-in-out;
	-webkit-animation-delay:.9s;
	animation-delay:.45s;
	transition:1.5s;
	transition-delay:1s;
	z-index: 300;
}

#send{ 
	width:   100%;
	padding: 0;	
	border:  none;
	cursor:  pointer;
	background: #166176;
	color: #fff;
	transition:.35s;
	letter-spacing: 1px;
    padding: 7px 0;
}
#send:hover{background:tomato;transition:.35s;}



@-webkit-keyframes message_animation {
	0%{
		transform:translatey(0px);
		z-index: 300;
		transition: 1s ease-in-out;
	}
	50%{
		transform:translatey(-340px);
		z-index: 300;
		transition: 1s ease-in-out;
	}
	51%{
		transform:translatey(-340px);
		z-index: 350;
		transition: 1s ease-in-out;
	}
	100%{
		transform:translatey(0px);
		z-index: 350;
		transition: 1s ease-in-out;
	}
}


@keyframes message_animation {
	0%{
		transform:translatey(0px);
		z-index: 300;
		transition: 1s ease-in-out;
	}
	50%{
		transform:translatey(-340px);
		z-index: 300;
		transition: 1s ease-in-out;
	}
	51%{
		transform:translatey(-340px);
		z-index: 350;
		transition: 1s ease-in-out;
	}
	100%{
		transform:translatey(0px);
		z-index: 350;
		transition: 1s ease-in-out;
	}
}

@-webkit-keyframes message_animation2 {
	0%{
		transform:translatey(0px);
		z-index: 350;
		transition: 1s ease-out;
	}
	50%{
		transform:translatey(150);
		z-index: 350;
		transition: 1s ease-out;
	}
	51%{
		transform:translatey(-340px);
		z-index: 300;
		transition: 1s ease-out;
	}
	100%{
		transform:translatey(0px);
		z-index: 300;
		transition: 1s ease-out;
	}
}


@keyframes message_animation2 {
	0%{
		transform:translatey(0px);
		z-index: 350;
		transition: 1s ease-in-out;
	}
	50%{
		transform:translatey(150);
		z-index: 350;
		transition: 1s ease-in-out;
	}
	51%{
		transform:translatey(-340px);
		z-index: 300;
		transition: 1s ease-in-out;
	}
	100%{
		transform:translatey(0px);
		z-index: 300;
		transition: 1s ease-in-out;
	}
}

.confidentail-div{
    width: 351px;
    height: 207px;
    position: absolute;
    text-align: center;
    z-index: 9999;
    top: 40px;
    img{
        width: 90%;
    }
}


}