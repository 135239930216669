.AMCS_ADV_1 {

  /*
      *******************************************
      Template Name: Medicare Saver
      Author: Manu Joseph
      Date: 23-02-2022
      Template By: Vandalay Designs
      Copyright 2020-2021 Vandalay Business Solution
  
      * This file contains the styling for the Medicare Saver, this
      is the file you need to edit to change the look of the
      site.
  
      This files contents are outlined below >>>>
  
      *** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
      *******************************************
      ============================================
      ==== T A B L E   O F   C O N T E N T S =====
      ============================================
      01 - Sass Variables
      02 - BODY 
      03 - FOOTER 
      04 - MEDIA QUERY
      ============================================
      ============================================
      */
  /*================================================
      01 - Sass Variables
      ==================================================*/
  $background_color_1: #ffffff;
  $background_color_1: #000;
  $color_1: #ffffff;
  $color_2: #000000;
  $font_family_1: 'Roboto', sans-serif;
  $font_family_2: 'Oswald', sans-serif;
  $font_family_3: 'Open Sans', sans-serif;
  $font_size_1: 12px;
  $font_size_2: 14px;
  $font_size_3: 15px;
  $font_size_4: 16px;
  $font_size_5: 18px;
  $font_size_6: 20px;
  $font-bold: bold;
  $font-normal: normal;
  $font-w500: 500;
  // ======Border Styles===============
  $border_color_1: #fff;
  /*================================================
      02 - BODY 
      ==================================================*/
  .hide {
    display: none !important;
  }
  .show {
    display: block !important;
  }
  .txl {
    text-align: left !important;
  }
  .txr {
    text-align: right !important;
  }
  header {
    background: #006699;
    padding: 5px 160px;
    .logo {
      width: 60%;
    }
    .heading_btn {
      background: #7ac143;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
      border-radius: 0px;
      padding: 8px 7px;
      margin-top: 6px;
      img {
        height: 19px;
        position: relative;
        top: -1px;
      }
    }
    .hvr-grow-rotate:hover {
      transform: scale(1.1) rotate(2deg);
      filter: brightness(1.1);
      transition-duration: .3s;
      transition-property: transform;
      vertical-align: middle;
      backface-visibility: hidden;
    }
  }
  .banner_section {
    padding: 15px 180px;
    .adv_txt {
      text-align: center;
      font-family: $font_family_3 !important;
      margin-bottom: 30px;
      font-size: 14px;
      .span_1 {
        color: #100f0f;
      }
      .span_2 {
        color: #006699;
        font-weight: 700;
      }
      .span_3 {
        color: #b2b2b2;
      }
    }
    h1 {
      font-family: $font_family_2 !important;
      font-size: 29px;
      font-weight: 700;
      color: #000;
      margin-bottom: 25px;
    }
    .banner {
      width: 100%;
      margin-bottom: 5px;
    }
    .link_txt {
      font-size: 19px;
      strong {
        background: #ff0;
      }
    }
    p {
      font-size: 19px;
    }
    ul {
      padding: 0px;
      li {
        list-style: none;
        font-size: 19px;
        background: url(../../AMCS_ADV_1/img/tick.png) no-repeat;
        padding: 0px 0px 6px 40px;
      }
    }
  }
  .first_state_section {
    background: #F7F9Fa;
    padding: 15px 0px;
    border-top: 2px solid #ecf0f1;
    border-bottom: 2px solid #ecf0f1;
    h2 {
      font-size: 29px;
      font-weight: 600;
      margin-bottom: 30px !important;
      font-family: "Roboto", sans-serif !important
    }
    .round_image {
      height: 75px;
      width: 84px;
      border-radius: 60px
    }
    img {
      height: 70px;
      margin-bottom: 15px;
    }
    p {
      font-size: 19px;
    }
    .grow-rotate:hover {
      transform: scale(1.1) rotate(4deg);
    }
  }
  .midpage_cta {
    background: #F7F9Fa;
    padding: 15px 0px 35px 0px;
    border-top: 2px solid #ecf0f1;
    border-bottom: 2px solid #ecf0f1;
    .age_btnbg {
      a {
        background-color: #008000;
        padding: 25px 15px;
        width: 120px;
        margin: 10px 2px;
        line-height: 45px;
        color: #fff !important;
        border-radius: 0px;
        font-size: 18px;
        text-decoration: none;
        font-weight: 700;
      }
    }
    .btm_btn {
      background: #1e344a;
      width: 100%;
      border-top: 2px solid #fbaa34;
      border-bottom: 2px solid #fbaa34;
      padding: 25px 15px;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      margin-top: 30px;
      &:hover {
        color: #fff;
      }
      img {
        height: 23px;
      }
    }
    h2 {
      font-size: 27px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: center;
      color: #006699;
    }
    .round_image {
      height: 75px;
      width: 84px;
      border-radius: 60px
    }
    p {
      font-size: 19px;
    }
    .grow-rotate:hover {
      transform: scale(1.1) rotate(4deg);
    }
  }
  .txt_section {
    padding: 15px 180px;
    p {
      font-size: 19px;
    }
  }
  .btm_section {
    background: #213142;
    padding: 15px 180px;
    img {
      height: 150px;
    }
    p {
      font-size: 33px;
      color: #fff;
      line-height: 43px;
      margin-top: 30px;
    }
    .btm_btn {
      background: #1e344a;
      width: 100%;
      border-top: 2px solid #fbaa34;
      border-bottom: 2px solid #fbaa34;
      padding: 25px 15px;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      margin-top: 30px;
      &:hover {
        color: #fff;
      }

      img {
        height: 23px;
      }
    }
    .hvr-grow-rotate:hover {
      transform: scale(1.1) rotate(4deg);
    }
    .hvr-grow-rotate {
      vertical-align: middle;
      transform: translateZ(0);
      box-shadow: 0 0 1px transparent;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-osx-font-smoothing: grayscale;
      transition-duration: .3s;
      transition-property: transform;
    }
  }
  /*================================================
      03 - FOOTER
  ==================================================*/
  footer {
    padding: 60px 180px 15px 180px;
    background: #eceded;
    .m-0 {
      margin: 0px;
    }
    ul {
      padding: 0px;
      margin-top: 15px;
      li {
        list-style: none;
        border-right: 1px solid #551a8b;
        padding: 0px 10px;
        display: inline;

        &:first-child {
          padding-left: 0px;
        }
        &:last-child {
          border: 0px;
        }
        a {
          text-decoration: none;
          color: #551a8b;
        }
      }
    }
    p {
      font-size: 16px;
      margin-bottom: 30px;
      border-top: 1px solid #d8d8d8;
      padding-top: 20px;
    }
  }
  .cookie {
    background: #5d5c5c;
    width: 100%;
    position: fixed;
    bottom: 0px;
    padding: 5px 15px;
    p {
      a {
        color: red;
        text-decoration: underline;
        cursor: pointer;
      }
      color: #fff;
      font-size: 13px;
      margin-bottom: 0px;
    }
    .cookie_btn {
      background: #f1d600;
      font-weight: 600;
      font-size: 16px;
      padding: 5px 60px;
    }
  }
  /*================================================
      04 - MEDIA QUERY
  ==================================================*/
  @media (max-width: 1024px) {
    .first_state_section {
      h2 {

        text-align: center;
      }
    }
    .first_state_section_2 {
      h2 {
        text-align: center;
      }
    }
  }
  @media (max-width: 768px) {
    header {
      padding: 10px 0px 10px 0px;
    }
    .banner_section {
      padding: 15px 0px;

      h1 {
        font-family: $font_family_1 !important;
        text-align: center;
      }
    }
    .midpage_cta {

      .age_btnbg a {
        width: 100px;
      }
    }
    .txt_section {
      padding: 15px 0px;
    }
    .btm_section {
      padding: 15px 0px;
    }
    .first_state_section {
      h2 {

        text-align: center;
      }
    }
    .first_state_section_2 {
      h2 {
        text-align: center;
      }
    }
  }
  @media (min-width: 603px) {
    .btm_section {
      .tac {
        text-align: center !important;
      }
    }
    .midpage_cta {

      .age_btnbg a {
        width: 125px;
      }
    }
  }
  @media (max-width: 576px) {
    header {
      padding: 10px 0px 10px 0px;
      h4 {
        font-size: 19px;
      }
      .heading_btn {
        font-size: 17px;
      }
      .logo {
        width: 100%;
        margin-top: 17px;
      }
    }
    .banner_section {
      padding: 15px 0px;
      h1 {
        font-family: $font_family_1 !important;
        text-align: center;
      }
    }
    .txt_section {
      padding: 15px 0px;
    }
    .first_state_section {
      h2 {
        font-size: 25px;
        text-align: center;
      }
    }
    .first_state_section_2 {
      h2 {
        font-size: 25px;
        text-align: center;
      }
    }
    .btm_section {
      padding: 15px 0px;
      .tac {
        text-align: center !important;
      }
      p {
        font-size: 24px;
        line-height: 31px;
        text-align: center;
      }
    }
    footer {
      padding: 60px 15px 15px 15px;
    }
    .cookie .cookie_btn {
      padding: 5px 15px;
      width: 100%;
      margin-top: 15px;
    }
  }
  @media (min-width: 320px) {
    .midpage_cta {

      .age_btnbg a {
        width: 90px;
      }
    }
  }
}