.ADV-CLAIMS{

/*
*******************************************
Template Name: Reclaimer
Author: Nikhil Emmanuel
Date: 18-05-2022
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for Reclaimer, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - SECTION 
06 - FOOTER 
07 - MODAL 
08 - MEDIA QUERY

============================================
============================================

*/


/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========

$background_color_1:#011a70;
$background_color_2:#0e41ef;
$background_color_3:#0e35b3;

// ======font styles===============

$color_1:#303291;

$font_size_1:35px;
$font_size_2:27px;
$font_size_3:30px;
$font_size_4:20px;
$font_size_5:22px;
$font_size_6:25px;

// ======border===========

/*================================================
02 - Sass Mixins
==================================================*/

/*================================================
03 - BODY STYLES
==================================================*/

font-family: 'Roboto', sans-serif !important;
font-size: 16px !important;
line-height: 1.5 !important;
background: #fff !important;
color: #272727 !important;

html {
	scroll-behavior: smooth;	
}

body{
    font-family: 'Roboto', sans-serif !important;
	font-size: 16px;
    line-height: 1.5;
	background: #fff !important;
	color: #272727 !important;
}

/*================================================
04 -header
==================================================*/
header{
    background: #fff;
    .logo{
        img{
            width: 60%;
        }
    }
    p{
        font-size: 18px;
        font-weight: 700;
    }
    .claimbtn{
        background-color: #0e35b3;
        border-radius: 15px;
        width: 36%;
        line-height: 47px;
        border-bottom: 6px solid #396ee9;
        font-weight: 700;
        font-size: 20px;
    }
    padding: 25px;
}

/*================================================
05 -content_sec
==================================================*/

.content_sec{
    h1{
        font-size: $font_size_1;
        font-weight: 700;
    }
    h2{
        font-size: $font_size_2;
        font-weight: 700;
    }
    h4{
        font-size: 22px;
        font-weight: bold;

    }
    h3{
        color: #303291 !important;
        font-size: 35px;
        font-weight: 700;
    }
    .yellows{
        background-color: yellow;
    }
    .cta-section{
        .bannersec{    
            h6{
                font-size: 28px;
                background-color: $background_color_1;
                font-weight: bold;
            }
            .age_brdr{
                // border-color: $background_color_1 !important;
                border: 5px solid $background_color_1 !important;
                border-top: 0px;
                .triangle_shape {
                    width: 0;
                    height: 0;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-top: 20px solid #001b72;
                }
                .goldbtn {
                    margin: 10px 2px 0px 2px;
                    line-height: 47px;
                    font-size: 20px;
                    width: 48%;
                    background-color: $background_color_3;
                    -webkit-box-shadow: inset 0px -5px 8px 0px #0000005c;
                    -moz-box-shadow: inset 0px -5px 8px 0px #0000005c;
                    box-shadow: inset 0px -5px 8px 0px #0000005c;
                    &:hover {
                        text-decoration: none;
                        background: $background_color_2;
                    }
                }
                // .goldbtnfirst {
                //     background: linear-gradient(360deg, #011a70 0%, rgba(11,46,163,0.944415) 62%);
                //     &:hover {
                //         text-decoration: none;
                //         background: $background_color_2;
                //     }
                // }
            }
        }
    }
    .banner11{
        img{
            width: 75%;
        }
    }
    h3{
        color: $color_1;
        font-size: $font_size_1;
        font-weight: bold;
    }

    .fixedsticky{
        top: 25px;
        position: sticky;
    }
}

.new_class {
    color: #007bff;
}
/*================================================
06 -footer
==================================================*/

footer{
    background-color: #0a0641;
    img{
        height: 90px;
    }
    .pads{
        padding: 0px;
    }
}

/*================================================
09 - MEDIA QUERY
==================================================*/

$break-ipad-pro: 1024px;
$break-min-ipad: 768px;
$break-max-ipad: 991px;
$break-min-tab: 577px;
$break-max-tab: 767px;
$break-mob: 576px;

@media (min-width:$break-max-ipad)  and (max-width: $break-ipad-pro) {
    
}
    
@media (min-width:$break-min-ipad) and (max-width:$break-max-ipad) {

    .claimbtn{
        width: 60% !important;
    }
  
}

@media (min-width:$break-min-tab) and (max-width:$break-max-tab) {
      
}

@media screen and (max-width: $break-max-tab) {

    .content_sec{
        padding: 5px 0px 30px 0px !important;
    }
    .claimbtn{
        width: 90% !important;
        line-height: 40px !important;
    }
    .bannersec{
        .goldbtn{
            width: 98% !important;
        }
    }
    footer{
        img{
            display: none;
        }
    }
}

@media screen and (max-width: $break-mob) {
    header{
    padding: 15px 0px !important;
        .logo{
            img{
                width: 100%;
            }
        }
    }
    .content_sec{
        h1{
            font-size: $font_size_6 !important;
        }

        h2{
            font-size: $font_size_5 !important;
        }

        h4{
            font-size: $font_size_4 !important;
        }
        h3{
            font-size: $font_size_3 !important;
        }

        .liness{
            .yellows{
                p{
                    font-size: 14px !important;
                }
            }
        }
        .banner11{
            img{
                width: 100% !important;
            }
        }
    }
    footer{
        .pads{
            padding: 0px 15px;
        }
    }  
}

}