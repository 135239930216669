@import "node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
/*
*******************************************
Template Name: Boomerang Claims Thankyou page
Author: Abhilash GP
Date: 04-07-2022
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the Boomerang Claims, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - 
05 - 
06 - FOOTER
07 - MODAL
08 - Animation
09 - MEDIA QUERY

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/
.Thankyou_V2{
$background_color_1:#ffffff;
$background_color_2:#ff7900;
$background_color_3:#166176;
$background_color_4:#c7dbde;
$background_color_5:#22697d;


$color_1:#FFFFFF;
$color_2:#222222;
$color_3: #ff7900;
$color_4: #166176;


$font_family_1:'Poppins', sans-serif !important;

$font_size_1:16px;
$font_size_2:14px;




// ======border===========
$border-style1:1px dashed #ffffff;




/*================================================
02 - Sass Mixins
==================================================*/

/*================================================
03 - BODY STYLES
==================================================*/
.thankyoupage{
    min-height: inherit !important;
    .banner-part{
        img{
            margin: 10px 0 10px;
        }
    }
}
.thankyou{
    background-color: $background_color_4;
    padding: 0px 0 20px !important;
    text-align: center;
    img{
        height: 200px;
    
    }
    h1{
        font-weight: 600;
        font-size: 44px;
        color:$color_4 !important;
        padding: 30px 0 0;
        text-align: center;
    }
    h2{
        font-weight: 600;
        font-size: 24px;
        color:$color_4  !important;
        padding: 20px 0;
        text-align: center;
    }
    h3{
        font-weight: 500;
        font-size: 18px;
        color:$color_4 !important;
        padding: 10px 0;
        text-align: center;
    }
}



/*================================================
08 - Animation
==================================================*/


/*================================================
09 - MEDIA QUERY
==================================================*/




@media (min-width:1200px) and (max-width : 1440px){
   
}

$break-ipad-pro: 1024px;

$break-min-ipad: 768px;
$break-max-ipad: 990px;

$break-min-tab: 577px;
$break-max-tab: 767px;

$break-mob: 576px;

@media (min-width:$break-max-ipad)  and (max-width: $break-ipad-pro) {
   
}

    
@media (min-width:$break-min-ipad) and (max-width:$break-max-ipad) {
   
}


@media (min-width:$break-min-tab) and (max-width:$break-max-tab) {
       
}

@media screen and (max-width: $break-mob) {   
   
}

@media screen and (max-width: $break-max-tab) {   
    .thankyou{
        h1{
            font-size: 36px;
        }
        img{
            height: 121px;
        }
        h2{
            font-size: 22px;
        }
    }

}
}