/*
*******************************************
Template Name: SSBGroup
Author:Shinto
Date:21-07-2021
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the ssblaw.co.uk, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************
============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - FOOTER 
05 - MEDIA QUERY
/*================================================
01 - Sass Variables
==================================================*/
.signaturePage {
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
  $color_1_main: #333;
  $font_family_1: 'Open Sans', sans-serif;
  $background_color_1: #ffffff;
  $background_color_2: #f5ad34;
  $background_color_3: #545454;
  $background_color_4: #f7a900;
  $background_color_5: #545454;
  $background_color_9: #f3f3f3;
  $border_color_1: #5b97ce;
  $border_1: #69a2d7;
  $border_2: #5f5f5f;
  $color_1: #ffffff;
  $color_2: #636363;
  $color_3: #5b97ce;
  $color_4: #beb8b8;
  $color_6: #fce4c7;
  $color_7: #000;

  html {
    scroll-behavior: smooth;
  }
  a {
    cursor: pointer;
  }
  /*================================================
    03 - Body
  ==================================================*/
  .signature-body {
    background: url(/assets/Signature/img/banner.jpg) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    background-position: center;
    padding: 15px 0px;
    margin-bottom: 20px;

    font-family: $font_family_1;
    font-size: 15px;
    line-height: 1.5;
    color: $color_1_main;
  }
  @media screen and (min-width: 1px) {
    input.radio {
      position: absolute;
      left: -9999px;
    }
  }
  .sign-body {
    background: $background_color_2;
  }
  .signature-part {
    background: $background_color_1;
    border: 1px solid #cecece;
    border-radius: 10px;
    padding: 15px 0px 0px;
    margin: 15px 0 !important;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, .63);
    .sign-logo {
      height: 90px;
    }
    h1 {
      font-size: 26px;
      color: #222222;
      font-weight: 700;
      margin: 20px 0;
      padding: 0px;
    }
    .modal-content {
      border: 0 !important;
      border-radius: 0 !important;
    }
    .modal-header {
      margin: 0px;
      padding: 0px;
      .h2_style {
        text-align: center;
        font-size: 18px;
        color: rgb(255, 255, 255);
        padding: 20px 15px;
        font-weight: 600;
        background: #C40046;
        margin-bottom: 0px;
      }
    }
    .ensure_li {
      margin: 0px;
      padding: 0px;
      .sign-question {
        .signature-ul {
          text-align: center;
          padding: 0;
          margin: 35px 0 15px 80px;
          li {
            background: url(/assets/Signature/img/arrow-li.png) no-repeat 0;
            list-style: none;
            padding: 5px 0 5px 35px;
            margin-bottom: 0;
            font-size: 17px;
            border: 0 !important;
            text-align: left;
            display: block;
          }
        }
      }
      .signature_canvaseWrap {
        .signature-pad {
          border: 1px dotted #000 !important;
          background-color: #fff;
          border-radius: 5px;
          display: block;
          margin: 20px auto;
          position: relative;
          width: 80%;
          height: 300px;
        }
      }
    }
    .bord {
      padding: 5px 25px;
      .custom-control-label {
        font-size: 16px;
        line-height: 1.5em;
        padding-left: 5px;
      }
    }
    /*================================================
            04 - Footer
    ==================================================*/
    .modal-footer {
      margin-top: 8px;
      .btn-clear,
      .btn-submit {
        font-size: 15px;
        color: #fff;
        font-weight: 400;
        font-size: 20px !important;
        border-radius: 4px;
        border: 0;
        width: auto;
        cursor: pointer;
        text-transform: uppercase;
      }
      .btn-clear {
        background-color: #fb0404;
        padding: 9px 15px;
      }
      .btn-submit {
        background-color: #309814;
        padding: 9px 50px;
        float: right;
      }
    }
  }
  .error_msg {
    color: #fff;
    width: 100%;
    float: left;
    background: red;
    font-size: 13px;
    padding: 0 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 0;
    text-align: center;
  }
  .sign_msg {
    color: #fff;
    width: 80%;
    background: red;
    font-size: 13px;
    padding: 0 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 0;
    text-align: center;
    margin: 0px auto;
  }
  /*================================================
        05 - Media Query
  ==================================================*/
  @media (min-width: 576px) {
    .signature-pad {
      width: 400px;
    }
  }
  @media (max-width: 576px) {
    .signature-part {
      .modal-header {
        .h2_style {
          padding: 14px 8px;
          font-size: 14px;
        }
      }
      .bord {
        padding: 5px 5px;

        .custom-control-label {
          font-size: 14px;
        }
      }
      .modal-footer {
        .btn-clear,
        .btn-submit {
          font-size: 14px !important;
        }
      }
      .ensure_li {
        .sign-question {
          .signature-ul {
            margin: 35px 0 15px 0px;

            li {
              font-size: 15px;
              padding: 5px 0 5px 32px;
            }
          }
        }
        .nopadmob {
          padding: 0px 5px;
        }
      }
    }
  }
}