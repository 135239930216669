@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

.boomer_thanku{

  /*
  *******************************************
  Template Name: Boomerang Claims
  Author:  Arun Ravi
  Date:02-06-2022
  Template By: Vandalay Designs
  Copyright 2021-2022 Vandalay Business Solution
  
  * This file contains the styling for the Boomerang Claims, this
  is the file you need to edit to change the look of the
  site.
  
  This files contents are outlined below >>>>
  
  *** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
  *******************************************
  
  ============================================
  ==== T A B L E   O F   C O N T E N T S =====
  ============================================
  
  01 - Sass Variables
  02 - body
  03 - modal 
  04 - media query 
  
  ============================================
  ============================================
  
  */
  
  /*================================================
  01 - Sass Variables
  ==================================================*/
  
  $color_1:#ffffff;
  $color_2:#000;
  
  
  html {
      scroll-behavior: smooth;
    }
  
  a{cursor: pointer;}
  
  /*================================================
  02 - Body
  ==================================================*/
  
      header{
          padding: 15px 0;
          margin-bottom: 0;
          border-bottom: 1px solid #9a9a9a;
          background-color: $color_1;
  
          img{
              width: 22%;
          }
      }
      .content-section {
          background: $color_1;
          background-attachment: fixed;
          background-size: cover;
          padding: 55px 0px;
          min-height: 300px;
  
          img {
              height: 130px;
          }
          h1 {
              color: $color_2;
              padding: 5px 0;
              margin: 15px 0;
              font-size: 36px;
              font-weight: 800;
              font-family: 'Lato', sans-serif !important;
          }
          p {
              color: $color_2;
              padding: 0 15px;
              margin-bottom: 5px;
              font-size: 18px;
              font-family: 'Lato', sans-serif !important;
          }
      }
  
      .animated {
          animation-duration: 1s;
          animation-fill-mode: both;
      }
  
  
  /*================================================
  03 - Modal
  ==================================================*/
  

  /*================================================
  04 - Media Query
  ==================================================*/
  
  $max-break-tab:1199.98px;
  $min-break-tab:991px;
  $max-break-tablet:990px;
  $min-break-tablet:768px;
  $max-break-small: 767px;
  $min-break-small: 576px;
  $max-break-mob: 575px;
  
  @media (max-width:$max-break-small) {
      header{
          img {
              width: 50% !important;
          }
      } 
  }
  
  @media (max-width:$min-break-small) {

  }
  
}