$background_color_1:#ffffff;
$color_1:#ffffff;

$max-break-small: 767px;
$max-break-mob: 575px;

.modal {
    .modal-dialog {
        //float: right;
        //right: 9%;
        //top: 13%;

        .modal-content {
            background: transparent;
        }
    }

    .modal-body {
        border: 3px solid #004c70;
        border-radius: 0px;
        width: 100%;
        padding: 20px 15px 25px;
        background-color: $background_color_1;

        ul{
            padding: 0;
            display: inline;
            li{
                list-style: none;
                display: inline-block;
                input[type=checkbox], input[type=radio] {
                    visibility: hidden;
                    position: absolute;
                }
                label{
                    background: #cd2653;
                    color: #fff!important;
                    font-size: 16px;
                    font-weight: 600;
                    /*width: 200px;*/
                    padding: 15px 0;
                    display: inline-block;
                    border-radius: 4px;
                    text-decoration: none!important;
                    cursor: pointer;
                    margin-bottom: 8px;
                    border-radius: 10px;
                    &:hover {
                        /*background: #598bff;*/
                        background: #80a2f1;
                    }
                }
                input[type=checkbox]:checked+label{
                    background: #598bff;
                }
            }
        }
    }

    .product-list{
        li{
            list-style: none;
            display: inline-block;
            input[type=checkbox], input[type=radio] {
                visibility: hidden;
                position: absolute;
            }
            label{
                background: #cd2653 !important;
                color: #fff!important;
                font-size: 16px;
                font-weight: 600;
                width: 200px !important;
                padding: 15px 0;
                display: inline-block;
                border-radius: 4px;
                text-decoration: none !important;
                cursor: pointer;
                margin: 5px;
                border-radius: 10px;
                &:hover {
                    background: #cd2653 !important;
                }
            }
            
        }
        .btn {
            font-size: 16px;
            min-width: 100px;
        }   
    }

    h2 {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #C40046;
        margin-bottom: 20px;
        line-height: 37px;
        text-transform: uppercase;
    }
    h3{
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #C40046;
        // margin-bottom: 20px;
        line-height: 37px;
        text-transform: uppercase;
    }

    p {
        font-size: 15px;
        line-height: normal;

        img {
            width: 100%;
            height: 17px;
            margin-bottom: 10px;
        }


    }

    ul.options {
        display: block;
        padding: 0px 0 0 0;
        list-style: none;
        margin-bottom: 15px;
        margin-left: 0px;

        li {
            margin: 0 1% 1% 0;
            width: 100%;
            float: left;
            cursor: pointer;
        }
    }

    .custom-checkbox {
        display: none;
    }

    .custom-checkbox+label {
        background: #ececec 7px center;
        border: 1px solid #b4b4b4;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        // height: 50px;
        position: relative;
        cursor: pointer;
        outline: none;
        margin: 0;
        padding: 15px;
        line-height: 18px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        width: 100%;
        clear: none;
        color: #333;
        font-weight: bold;
        display: block;
        font-size: 16px;
        text-align: center;

        // -webkit-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
        // -moz-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
        // box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
        &:hover {
            background: #C40046;
            color: $color_1;
        }
    }

    .custom-checkbox:checked+label {
        background: #C40046 center;
        color: $color_1;
        border: 1px solid #006350;
    }
    .btn {
        background: #468700;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        min-width: 200px;
        padding: 10px 35px;
        border-radius: 10px;
        &:hover {
            background: #84b79f;
            color: #fff;
        }
    }
}
.modal-backdrop.show{
    opacity: .7 !important;
}

.modal-sp {
    .modal-content {
        background: #fff !important ;
    }

    .modal-body-sp {
        padding: 20px 40px 0;
        background-color: #fff;
        border: none;
        h2 {
            font-weight: inherit;
            text-align: center;
            margin-bottom: 10px;
            line-height: normal;
            text-transform: inherit;
            padding: 0;
            color: #0a0d49;
            font-size: 20px;
        }
        h3 {
            font-size: 14px;
            color: #333;
            text-align: center;
            font-weight: 400;
            line-height: 21px;
            text-transform: none;
        }
        img {
            // max-width: 300px;
            max-width: 100%;
            width: 100%;
            height: auto;
            border: 1px solid #000;
            height: 114px;
        }
    }

    .modal-footer-sp {
        margin-top: 8px;
        background-color: #fff;
        display: block;
        .btn-clear,
        .btn-submit {
            min-width: inherit;
            padding: inherit;
            font-size: 15px;
            color: #fff;
            font-weight: 400;
            font-size: 16px !important;
            border-radius: 4px;
            border: 0;
            width: auto;
            cursor: pointer;
            text-transform: uppercase;
        }
        .btn-clear {
            background-color: #fb0404;
            padding: 9px 15px;
        }
        .btn-submit {
            background-color: #309814;
            padding: 9px 50px;
            float: right;
        }
    }
}

@media (max-width:$max-break-mob) {
    .modal {
        .modal-dialog {
            right: 0;

        }

        h2 {
            font-size: 25px;
        }

        ul.options {
            li {
                margin: 0 1% 4% 0;
                width: 100%;
            }
        }
    }
}