.followupPage {

  /*
*******************************************
Template Name: Boomerang Claims
Author: Abhilash GP
Date: 19-07-2022
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Boomerang Claims, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - 
05 - MODAL
06 - MEDIA QUERY

============================================
============================================

*/


  /*================================================
01 - Sass Variables
==================================================*/

  $background_color_1: #ffffff;
  $background_color_2: #ff7900;
  $background_color_3: #166176;
  $background_color_4: #c7dbde;
  $background_color_5: #2aa406;


  $color_1: #FFFFFF;
  $color_2: #222222;
  $color_3: #ff7900;
  $color_4: #166176;


  $font_family_1: 'Poppins',
  sans-serif;

  $font_size_1: 16px;
  $font_size_2: 14px;




  // ======border===========
  $border-style1: 1px dashed #ffffff;




  /*================================================
02 - Sass Mixins
==================================================*/

  /*================================================
03 - BODY STYLES
==================================================*/



  a {
    color: #ff7900 !important;
    cursor: pointer;
  }

  body {
    font-family: $font_family_1 !important;

  }



  header {
    padding: 10px 0;
    min-height: auto;

    img {
      height: 70px;
    }
  }

  .img-div {
    position: absolute;
    bottom: 0;
    width: 100%;

    img {
      width: 100%;
      opacity: 0.6;
    }
  }

  .myDiv2 {
    background: url(/assets/UserDocument/img/bg.png) no-repeat bottom right !important;
    font-family: 'Poppins', sans-serif !important;
  }

  .bglogo {
    opacity: 0.6;
  }

  #mymg {
    filter: hue-rotate(187deg);
  }

  .loader-mob {
    padding-top: 35px;
  }

  /*================================================
03 - CONTENT SECTION
==================================================*/
  .content {
    padding: 20px 0;

    .intro {
      margin-bottom: 20px;

      img {
        height: 150px;
      }

      h2 {
        color: $color_3 !important;
        font-weight: 600;
        font-size: 26px;
        margin: 15px 0;
        padding: inherit !important;
        text-align: center !important;
      }

      h3 {
        color: $color_2 !important;
        font-weight: 600;
        font-size: 20px;
        padding-bottom: 15px;
        text-align: center !important;
      }

      p {
        color: $color_2;
        font-weight: 400;
        font-size: 14px;
        font-style: italic;
      }

    }

    .doc-op {
      ul {
        margin-bottom: 40px;
        padding: 0px;

        li {
          display: inline;
          font-size: 13px;
          font-style: italic;
          font-weight: 600;
          padding: 0 10px;
          color: $color_2;
          border-right: solid 3px $color_3;

          &:last-child {
            border: 0px;
          }
        }
      }
    }

    .uploading-part {
      .id-box {
        padding: 20px;
        box-shadow: -1px 0px 22px 8px rgba(0, 0, 0, 0.10);
        -webkit-box-shadow: -1px 0px 22px 8px rgba(0, 0, 0, 0.10);
        -moz-box-shadow: -1px 0px 22px 8px rgba(0, 0, 0, 0.10);
        border-radius: 0 0 20px 20px;
        margin: 0px;
        margin-bottom: 15px;
        background-color: rgba(255, 255, 255, 0.6);
      }

      .upload-area {
        margin: 20px 0;
        border-radius: 20px;
        padding: 20px 0;
        text-align: center;
        background: $background_color_1;
        box-shadow: -1px 0px 22px 8px rgba(0, 0, 0, 0.10);
        -webkit-box-shadow: -1px 0px 22px 8px rgba(0, 0, 0, 0.10);
        -moz-box-shadow: -1px 0px 22px 8px rgba(0, 0, 0, 0.10);
      }
    }


  }

  .accordion-body {
    padding: 0px !important;
  }

  .accordion-item {
    background: none !important;
  }

  .accordion-button {
    background-color: $background_color_2 !important;
    margin-bottom: 15px;
    color: $color_1 !important;
    border-radius: 6px !important;

    &:focus {
      box-shadow: none !important;
    }

    &::after {
      border: solid 5px #eb6f01;
      padding: 15px;
      background-position: center;
      border-radius: 100%;
      background-color: $background_color_1;
    }

  }

  /*================================================
04 - BUTTON
==================================================*/

  .button-tab {
    background: $background_color_5;
    color: $color_1 !important;
    padding: 15px 0;
    border: 0px;
    width: 46%;
    margin: 1%;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    margin-top: 25px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    img {
      padding-right: 10px;

    }

    input[type=file] {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      display: block;
      cursor: pointer;
    }

    input[type=button] {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      display: block;
      cursor: pointer;
    }

    &:hover {
      background: $background_color_2 !important;
    }

  }

  .browsebut {
    background: $background_color_5 !important;
    cursor: pointer;

    &:hover {
      background: $background_color_2 !important;
    }
  }

  .red {
    background-color: $background_color_3 !important;
  }

  .skip-doc-outer {
    margin-bottom: 34px;

  }

  .skip-doc-btn {
    border: 3px solid red;
    color: red;
    padding: 12px 50px;
    font-size: 18px;
    cursor: pointer;
  }





  /*================================================
07 - MODAL
==================================================*/

  .modal-content {

    .modal-header {

      h4 {
        font-size: 28px;
      }
    }




    table {
      border-collapse: collapse;
    }

    tr {
      border-bottom: 1px solid #000;
    }

    td {
      border-right: 1px solid #000;
      padding: 5px;
    }


  }


  /*================================================
08 - Animation
==================================================*/




  /*================================================
09 - MEDIA QUERY
==================================================*/


  $break-ipad-pro: 1024px;

  $break-min-ipad: 768px;
  $break-max-ipad: 990px;

  $break-min-tab: 577px;
  $break-max-tab: 767px;

  $break-mob: 576px;


  @media (min-width:$break-min-tab) and (max-width:$break-max-tab) {}

  @media screen and (max-width: 577px) {
    .loader-mob {
      padding-top: 80px !important;
    }
  }

  @media screen and (max-width: $break-max-tab) {
    header {
      img {
        height: 50px !important;
      }
    }

    .button-tab {
      width: 94%;
      margin: 10px 1%;
      margin-top: 10px;
      font-size: 15px;
    }

    .content {
      min-height: 100vh;
      height: auto;

      .intro {
        img {
          height: 115px;
        }
      }
    }

  }


}